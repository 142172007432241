import { DeepParitalStores, Stores } from './createStore';

class StoreBase {
  public rootStore!: Stores;
  // public analytics!: AnalyticsStore;

  constructor(externalStore?: DeepParitalStores[keyof Stores]) {
    if (externalStore) {
      Object.assign(this, { ...externalStore });
    }
  }

  public init(rootStore: Stores) {
    this.rootStore = rootStore;
    return this;
  }
}

export default StoreBase;
