export class RootStore<T> {
  constructor(stores: T) {
    const combined = Object.keys(stores).reduce((acc: any, item: string) => {
      return {
        ...acc,
        // @ts-ignore
        [item]: stores[item].init(this),
      };
    }, {});
    Object.assign(this, { ...combined });
  }
}
